import {
  Button,
  ErrorMessage,
  FieldLabel,
  TextInput,
  ToastNotifications,
} from '@lightspeed/design-system-react'
import { useT } from '@transifex/react'
import classNames from 'classnames'
import React, { FormEvent, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import {
  PAGE_ROUTE,
  useTranslateValidationMessage,
  VALIDATION_MESSAGES,
} from '../../../../constants/constants'

interface CredentialsFormProps {
  onUsernameChange: (username: string) => void
  onPasswordChange: (password: string) => void
  onSubmit: () => void
  isSubmitting: boolean
  ssoUsername?: string
}

export const CredentialsForm: React.FC<
  React.PropsWithChildren<CredentialsFormProps>
> = ({
  onPasswordChange,
  onUsernameChange,
  onSubmit,
  isSubmitting,
  ssoUsername,
}) => {
  const t = useT()
  const history = useHistory()
  const translateValidation = useTranslateValidationMessage()

  const urlSearchQuery = new URLSearchParams(window.location.search)
  let usernameParamInReturnURL = ''
  // Check for a 'return' query parameter.
  const returnParam = urlSearchQuery.get('return') || ''
  if (returnParam && returnParam.includes('?')) {
    const returnURLQueryParams = returnParam.split('?')[1]
    const returnURLParams = new URLSearchParams(returnURLQueryParams)

    // If it's present, parse it, and check for a 'username' query parameter in that URL.
    // We'll use it to populate the username input during the OAuth2 flow.
    usernameParamInReturnURL = returnURLParams.get('username') || ''
  }

  const usernameParam = urlSearchQuery.get('username') || ''
  // Disable the username input if the 'username' parameter was provided.
  // This helps with iOS web login and user switching.
  const usernameInputDisabled =
    Boolean(ssoUsername) ||
    Boolean(usernameParam) ||
    Boolean(usernameParamInReturnURL) ||
    isSubmitting

  const [username, setUsername] = useState(
    ssoUsername || usernameParam || usernameParamInReturnURL
  )
  const [password, setPassword] = useState('')
  const [usernameErrMsg, setUsernameErrMsg] = useState('')
  const [passwordErrMsg, setPasswordErrMsg] = useState('')

  useEffect(() => {
    // This is to handle the case where the username
    // is retrieved from the URL query param.
    if (usernameInputDisabled) {
      onUsernameChange(username)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validateForm = () => {
    const validateField = (field: string, message: string) => {
      return field ? '' : translateValidation(message)
    }

    const usernameErrorMsg = validateField(
      username,
      VALIDATION_MESSAGES.usernameRequired
    )
    const passwordErrorMsg = validateField(
      password,
      VALIDATION_MESSAGES.passwordRequired
    )

    setUsernameErrMsg(usernameErrorMsg)
    setPasswordErrMsg(passwordErrorMsg)

    // Return true if both fields are filled in.
    return username && password
  }

  const handleForgotPasswordRedirect = async () => {
    history.push(PAGE_ROUTE.forgotPassword)
    return
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()

    if (!validateForm()) {
      ToastNotifications.negative(
        translateValidation(VALIDATION_MESSAGES.fieldsRequired)
      )
      return
    }

    onSubmit()
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="vd-pb5">
        <FieldLabel label={t('Username')}>
          <TextInput
            value={username}
            name="username"
            autoCorrect="off"
            autoFocus
            autoComplete="username"
            disabled={usernameInputDisabled}
            autoCapitalize="off"
            onChange={e => {
              setUsername(e.target.value)
              onUsernameChange(e.target.value)
            }}
            placeholder={t('Enter your username')}
            hasError={!!usernameErrMsg}
            data-testid="userName"
          />
          {usernameErrMsg && <ErrorMessage>{usernameErrMsg}</ErrorMessage>}
        </FieldLabel>
      </div>
      <div className="vd-pb5">
        <FieldLabel label={t('Password')}>
          <TextInput
            value={password}
            name="password"
            type="password"
            autoCorrect="off"
            autoCapitalize="off"
            autoComplete="current-password"
            onChange={e => {
              setPassword(e.target.value)
              onPasswordChange(e.target.value)
            }}
            placeholder={t('Enter your password')}
            hasError={!!passwordErrMsg}
            disabled={isSubmitting}
            data-testid="password"
          />
          {passwordErrMsg && <ErrorMessage>{passwordErrMsg}</ErrorMessage>}
        </FieldLabel>
      </div>
      <div className="vd-flex vd-flex--align-center vd-flex--justify-between vr-btn-wrap">
        <div
          className="vd-link vd-link--secondary"
          onClick={handleForgotPasswordRedirect}
        >
          {t('Forgot your password?')}
        </div>

        <Button
          data-track="signin"
          data-testid="signin"
          variant="go"
          type="submit"
          className={classNames('vr-signin-btn', {
            'vr-signin-btn--loading': isSubmitting,
          })}
          loading={isSubmitting}
          name="signin_submit"
        >
          {t('Log in', { _context: 'Login in button' })}
        </Button>
      </div>
    </form>
  )
}
