import { DomainName, Environment } from './types/Environment'

export const PRODUCTION_ENV: Environment = 'production'
export const DEVELOPMENT_ENV: Environment = 'development'
export const DOMAIN_NAME_VENDHQ: DomainName = 'vendhq'
export const DOMAIN_NAME_LSPD: DomainName = 'lightspeed'
export const PRODUCTION_DOMAIN = `.${DOMAIN_NAME_VENDHQ}.com`
export const LSPD_PRODUCTION_DOMAIN = `.${DOMAIN_NAME_LSPD}.app`

const DOMAIN_NAME_LSPD_CHECKER = `.${DOMAIN_NAME_LSPD}.`
const DOMAIN_NAME_GLSPD_CHECKER = '.gottagolightspeed.'

/**
 * Provides a safer way of determining if the app is running in production mode by using the current location href
 * (window.location.href) in addition to the environment string. This is useful in cases where the consumer might not
 * pass through the environment config (better than yolo defaulting to dev) or if the environment string is not 100%
 * correct (e.g. in nobob where environment is production but domain prefix is not production-like).
 */
export function isProduction(
  currentLocation: string,
  environment?: string
): boolean {
  const isProdDomain = isProductionDomain(currentLocation)
  return isProdDomain && (!environment || environment === PRODUCTION_ENV)
}

/**
 * Provides a safer way of determining if the app is running in development mode by using the current location href
 * (window.location.href) in addition to the environnment string. This is useful as we use the production images in
 * nobob but don't want to send UBEs, errors, etc to production trackers.
 */
export function isDevelopment(
  currentLocation: string,
  environment?: string
): boolean {
  // Regardless of domain, if the environment is `development` then we're in dev. This covers situations where we may
  // be in development but have a production-like domain (e.g. proxying via Charles to test in the iOS Register App).
  if (environment === DEVELOPMENT_ENV) {
    return true
  }

  // If a developer is using an import-map override, then we define the environment as `development`.
  // This prevents tracking errors and events normally sent in production.
  if (isImportMapOverrideEnabled() && hasExternalImportMapOverrides()) {
    return true
  }

  return !isProductionDomain(currentLocation)
}

/**
 * Determine whether the current location href (window.location.href) is a production URL (has domain *.vendhq.com or
 * lightspeed.app). If not then it must be development/test (e.g. *.dev.vendhq.works etc).
 */
export function isProductionDomain(currentLocation: string): boolean {
  return (
    currentLocation.indexOf(PRODUCTION_DOMAIN) > -1 ||
    currentLocation.indexOf(LSPD_PRODUCTION_DOMAIN) > -1
  )
}

/**
 * Determine whether the current URL has a lightspeed domain name (*.lightspeed.*)
 */
export function isLspdDomainName(currentLocation: string): boolean {
  return (
    currentLocation.indexOf(DOMAIN_NAME_LSPD_CHECKER) > -1 ||
    currentLocation.indexOf(DOMAIN_NAME_GLSPD_CHECKER) > -1
  )
}

/**
 * Determine whether a developer has enabled the import-map-overrides tool
 */
export const isImportMapOverrideEnabled = () => {
  return localStorage.getItem('devtools') === 'true'
}

/**
 * Determine whether a developer has defined an import-map override
 */
export const hasExternalImportMapOverrides = () => {
  return localStorage.getItem('import-map-overrides-external-maps') !== null
}
