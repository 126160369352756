import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ToastNotifications,
} from '@lightspeed/design-system-react'
import { NotFoundPage } from '@vend/react-utilities'
import { navigate, trackError } from '@vend/utilities'
import React, { useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useHistory, useLocation } from 'react-router-dom'
import { T, UT, useT } from '@transifex/react'
import { NewPassword } from '@vend/business-components'
import {
  PAGE_ROUTE,
  useGenericErrorMessage,
} from '../../../constants/constants'
import {
  VerifyTokenIDResponse,
  ResetPasswordResponse,
  ResetPasswordInput,
  resetPassword,
} from '../../../utils/api'
import { useRecaptcha } from '../../../utils/useRecaptcha'

export const ResetPassword = () => {
  const t = useT()
  const GENERIC_ERROR_MESSAGE = useGenericErrorMessage()
  const history = useHistory()

  const { state: tokenInfoState } = useLocation<VerifyTokenIDResponse>()
  const [isValidState, setIsValidState] = useState(false)
  const [isSubmiting, setIsSubmiting] = useState(false)

  const [password, setPassword] = useState('')
  const [hasClickedSubmit, setHasClickedSubmit] = useState(false)

  const {
    showRecaptcha,
    recaptchaKey,
    recaptchaRef,
    getRecaptchaToken,
    handleRecaptchaStatusUpdate,
  } = useRecaptcha()

  useEffect(() => {
    if (
      tokenInfoState &&
      tokenInfoState.user_id &&
      tokenInfoState.username &&
      tokenInfoState.token
    ) {
      return setIsValidState(true)
    }
  }, [tokenInfoState])

  const redirectToSignin = () => {
    history.push(PAGE_ROUTE.signin)
  }

  const redirectToBilling = (signupToken: string) => {
    navigate(`/signup/redirect/login/${signupToken}?next=billing`)
  }

  const handlePasswordChange = (password: string, isValid: boolean) => {
    if (isValid) {
      setPassword(password)
      return
    }

    setPassword('')
  }

  const handleResponse = (response: ResetPasswordResponse) => {
    if (response.status === 200) {
      if (tokenInfoState.is_initial_password_set && response.signup_token) {
        redirectToBilling(response.signup_token)
      } else {
        redirectToSignin()
      }
      return
    }
    handleRecaptchaStatusUpdate(response.require_captcha)
    ToastNotifications.negative(GENERIC_ERROR_MESSAGE)
    return
  }

  const handleSubmit = async () => {
    setHasClickedSubmit(true)

    if (password === '') {
      return
    }

    setIsSubmiting(true)
    try {
      const input: ResetPasswordInput = {
        user_id: tokenInfoState.user_id,
        token: tokenInfoState.token,
        is_initial_password_set: tokenInfoState.is_initial_password_set,
        password,
      }
      if (showRecaptcha) {
        input['g-recaptcha-response'] = await getRecaptchaToken()
      }

      const response = await resetPassword(input)
      handleResponse(response)
    } catch (error: any) {
      trackError(error)
      ToastNotifications.negative(GENERIC_ERROR_MESSAGE)
    } finally {
      setIsSubmiting(false)
    }
  }

  if (isValidState) {
    return (
      <main className="vd-flex vd-flex--align-center vd-flex--justify-center vr-main">
        <div className="vd-flex vd-flex--justify-center">
          {showRecaptcha && (
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={recaptchaKey}
            />
          )}
          <Modal focusTrapActive={true} pageModal size="small">
            <ModalHeader>
              <div className="vd-flex vd-flex--align-center">
                <div className="vd-text-heading">
                  {tokenInfoState.is_initial_password_set
                    ? t('Welcome to Lightspeed Retail!', {
                        _context: 'set password page header',
                      })
                    : t('Create a new password', {
                        _context: 'set password page header',
                      })}
                </div>
              </div>
            </ModalHeader>

            <ModalContent>
              <div className="vd-text vd-pb6">
                {tokenInfoState.is_initial_password_set
                  ? t('Please set your password for “{username}”.', {
                      username: tokenInfoState.username,
                      _context: 'set password modal header',
                    })
                  : t('Create a new password for “{username}” to continue.', {
                      username: tokenInfoState.username,
                      _context: 'set password modal header',
                    })}
              </div>
              <NewPassword
                onPasswordChange={handlePasswordChange}
                hasClickedSubmit={hasClickedSubmit}
              />
              <div className="vd-align-right vr-btn-wrap">
                <Button
                  data-track="create-password"
                  variant="go"
                  type="submit"
                  loading={isSubmiting}
                  onClick={handleSubmit}
                >
                  {t('Create password')}
                </Button>
              </div>
              {tokenInfoState.is_initial_password_set && (
                <p className="vd-text--sub vd-mt4">
                  <T
                    _context="List users page - Help section text with link"
                    _inline
                    _str={`By clicking "Create password" you agree to Lightspeed Retail's {termsOfUseLink} and confirm that you've read and acknowledged Lightspeed Retail's {privacyPolicyLink}.`}
                    termsOfUseLink={
                      <UT
                        _str={`<a class="vd-link" href="https://www.vendhq.com/terms-of-use" target="_blank" rel="noopener noreferrer">terms of use</a>`}
                        _inline
                        _context="term of user link"
                      />
                    }
                    privacyPolicyLink={
                      <UT
                        _str={`<a class="vd-link" href="https://www.vendhq.com/privacy-policy" target="_blank" rel="noopener noreferrer">privacy policy</a>`}
                        _inline
                        _context="privacy policy link"
                      />
                    }
                  />
                </p>
              )}
            </ModalContent>
          </Modal>
        </div>
      </main>
    )
  }

  return <NotFoundPage />
}
