import {
  Button,
  ErrorMessage,
  Header,
  Link,
  Modal,
  ModalContent,
  ModalHeader,
  ToastNotifications,
} from '@lightspeed/design-system-react'
import { T, useT } from '@transifex/react'
import { getCookie, trackError } from '@vend/utilities'
import classNames from 'classnames'
import React, { FormEvent, useEffect, useState } from 'react'
import {
  PAGE_ROUTE,
  useTranslateValidationMessage,
  VALIDATION_MESSAGES,
  VendDomainSuffix,
} from '../../../../constants/constants'
import { checkDomainPrefix } from '../../../../utils/api'
import { useDisplayLSIDCallbackError } from '../../../../utils/lsidCallbackError'

interface ConfirmStoreProps {
  domainSuffix: VendDomainSuffix
}

export const ConfirmStore: React.FC<
  React.PropsWithChildren<ConfirmStoreProps>
> = ({ domainSuffix }) => {
  const t = useT()
  const translateValidation = useTranslateValidationMessage()

  useDisplayLSIDCallbackError()

  // Get search params from url to carry over to sign in page
  const urlSearchQuery = window.location.search
  const SIGNIN_URL = `${domainSuffix}${PAGE_ROUTE.signin}`

  const domain = getCookie('store')?.split('|')[1] || ''
  const [domainPrefix, setDomainPrefix] = useState(domain)
  const [inputErrorMessage, setInputErrorMessage] = useState('')
  const [storeName, setStoreName] = useState('')

  // Read authenticatied domain prefix from cookie and get the store name.
  useEffect(() => {
    if (!domain) {
      return
    }
    checkDomainPrefix(domain)
      .then(({ valid, store_name: store }) => {
        if (valid && store) {
          setStoreName(store)
        }
      })
      .catch(error => {
        trackError(error)
      })
  }, [domain])

  const validateForm = () => {
    if (!domainPrefix) {
      setInputErrorMessage(
        translateValidation(VALIDATION_MESSAGES.domainPrefixRequired)
      )
      return false
    }

    setInputErrorMessage('')
    return true
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (!validateForm()) {
      ToastNotifications.negative(
        translateValidation(VALIDATION_MESSAGES.fieldsRequired)
      )
      return
    }
    checkDomainPrefix(domainPrefix)
      .then(({ valid, store_name: store }) => {
        if (valid && store) {
          window.location.assign(
            `https://${domainPrefix}${SIGNIN_URL}${urlSearchQuery}`
          )
        } else {
          setInputErrorMessage(
            translateValidation(VALIDATION_MESSAGES.domainPrefixInvalid)
          )
        }
      })
      .catch(error => {
        trackError(error)
        ToastNotifications.negative(
          translateValidation(VALIDATION_MESSAGES.generic)
        )
      })
  }

  return (
    <Modal focusTrapActive={false} pageModal size="small">
      <ModalHeader>
        <Header level="3" type="heading">
          {t('Log in to Lightspeed Retail (X-Series)')}
        </Header>
      </ModalHeader>
      <ModalContent>
        <form onSubmit={handleSubmit}>
          <div className="vr-store-field-wrap">
            <div className="vd-field vr-store-field">
              <div className="vd-label">{t('Store URL')}</div>
              <div className="vd-value">
                <input
                  className={classNames('vd-input', {
                    'vd-error': Boolean(inputErrorMessage),
                  })}
                  value={domainPrefix}
                  name="domainPrefix"
                  autoCorrect="off"
                  autoFocus
                  autoCapitalize="off"
                  onChange={e => {
                    setDomainPrefix(e.target.value)
                  }}
                  placeholder={t('Enter your store URL')}
                />
              </div>
            </div>
            <label className="vd-ml4 vr-input-suffix">{domainSuffix}</label>
          </div>

          {inputErrorMessage && (
            <ErrorMessage> {inputErrorMessage} </ErrorMessage>
          )}

          <div
            className={classNames(
              'vd-field vd-flex vd-flex--align-center vd-mt5 vr-pannel-footer vr-btn-wrap',
              {
                'vd-flex--justify-between': storeName,
                'vd-flex--justify-end': !storeName,
              }
            )}
          >
            {storeName ? (
              <p className="vd-text-body">
                <span>
                  <T
                    _str="You’re already signed in to {storeName}"
                    storeName={
                      <Link
                        data-track="switch-store"
                        href={`https://${domainPrefix}${SIGNIN_URL}${urlSearchQuery}`}
                        className="vd-ml1 vd-mr3"
                        isSecondary
                      >
                        {storeName}
                      </Link>
                    }
                  />
                </span>
              </p>
            ) : null}
            <Button data-track="signin-lightspeed-retail" type="submit">
              {t('Next')}
            </Button>
          </div>
        </form>
      </ModalContent>
    </Modal>
  )
}
