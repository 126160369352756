/**
 * Error data can be lost when stringifying an Error object. This function
 * converts an Error object to a string while preserving all properties.
 */
export const stringifyError = (error: Error): string =>
  JSON.stringify(error, Object.getOwnPropertyNames(error), 2)

/**
 * Error data can be lost when strigifying. This function identifies any
 * Error objects in the data and converts them to strings while preserving
 * all properties.
 */
export function preserveErrorsInData(data: any) {
  if (!data || !(typeof data === 'object')) {
    return data
  }

  if (data instanceof Error) {
    return stringifyError(data)
  }

  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => {
      if (value instanceof Error) {
        return [key, stringifyError(value)]
      }
      return [key, value]
    })
  )
}
