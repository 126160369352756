import React from 'react'

import { ButtonLink, Section } from '@lightspeed/design-system-react'
import { useT } from '@transifex/react'
import { VendImage } from '@vend/react-utilities'

export const NoLoginAccessPage: React.FC = () => {
  const t = useT()
  return (
    <div
      className="vd-flex vd-flex--justify-center vd-flex--align-center"
      style={{
        height: '100%',
        backgroundColor: 'var(--hs-color-bg-neutral-top)',
      }}
    >
      <Section>
        <div className="vd-align-center" style={{ maxWidth: '600px' }}>
          <VendImage
            width={250}
            relativeImagePath="error/wrong-user-permissions-v3.svg"
            alt={t('No permission', { _context: 'No login access' })}
          />
          <h1 className="vd-text-heading vd-mt5 vd-mb5">
            {t('You don’t have permission to log in to Lightspeed Retail', {
              _context: 'No login access - heading',
            })}
          </h1>
          <p className="vd-pb4">
            {t(
              'You’re assigned to a role that can only access Lightspeed Retail by switching users. An Admin or someone with a role that has log in access needs to log in first.',
              {
                _context: 'No login access - description',
              }
            )}
          </p>
          <ButtonLink
            data-track="no-login-access-back"
            href={`/signin${window.location.search}`}
          >
            {t('Back', { _context: 'No login access - Button' })}
          </ButtonLink>
        </div>
      </Section>
    </div>
  )
}
