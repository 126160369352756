import debounce from 'lodash/debounce'
import { trackError } from '../tracking/errorTracker'
import {
  isPerformanceSupported,
  startPerformanceMeasure,
  stopPerformanceMeasure,
} from '../tracking/performanceTracking'

export type MetricType = 'counter' | 'timer'
const metricTypeCounter = 'counter'
const metricTypeTimer = 'timer'
const doNotUseUBETracking = undefined
// metricsLogPrefix is the suffix of all the metrics logs.
const metricsLogPrefix = 'frontend_metrics'

// Tags represent Datadog tags.
// All the tags and its values need to be whitelisted in the backend endpoint
// /metrics (frontdoor).
export interface Tags {
  [name: string]: string
}

// Metric is an interface with the shared properties of both counters and
// timers
interface Metric {
  type: MetricType // type is either counter or timer.
  // name is the name of the metric.
  // Usually service.metric_name.
  // Example: webregister.sync_timer
  // When the metric is emited, it'll be suffixed with `femetrics.`
  // So in Datadog you'll reference it as femetrics.webregister.sync_timer
  name: string
  // The timestamp in ms for the creation of the metric. This will be avaluated
  // to determine if it should be discarded because it's too old.
  timestamp_ms: number
  tags: Tags // Datadog tags. See note above for the type Tags.
}

// TimerMetric represents a Datadog timer.
interface TimerMetric extends Metric {
  time_elapsed_ms: number // Measured time for the event in ms.
}

// CounterMetrics represents a Datadog counter.
interface CounterMetric extends Metric {
  increment: number // How much the counter is incremented.
}

// PendingMetrics are metrics that haven't been sent to the backend yet.
// This is because of our debouncing mechanism to avoid making too many
// requests to the backend.
// Metrics are accumulated until they can be sent in batches.
interface PendingMetrics {
  counters: Map<string, CounterMetric>
  timers: TimerMetric[]
}

// pendingUpdates is the variable where PendingMetrics are stored.
// See the comment for PendingMetrics.
const pendingUpdates: PendingMetrics = {
  counters: new Map<string, CounterMetric>(),
  timers: [],
}

// resetPendingUpdates deletes all pending updates.
// We use this after sending them to the backend.
export const resetPendingUpdates = () => {
  pendingUpdates.timers = []
  pendingUpdates.counters.clear()
}

// settings are the global metrics settings.
export const settings = {
  // debounceMin is the time that we wait for another metric update before
  // sending an update to the backend.
  // For example, if you increment a counter now, and no other metric is
  // updated within 500 ms, we'll send an update for your counter 500ms after
  // it being incremented (updated).
  // If a timer is started before 500 ms has passed, then they'll be grouped
  // together. That would push the metrics delivery to the backend by 500ms.
  // firs event => +100ms => next event => +500ms => sent 600ms from the
  // first event.
  // Could the 500ms delaying go on forever?
  // No. See debounceMax.
  // See https://lodash.com/docs/4.17.15#debounce for more details.
  debounceMin: 500,
  // Read debounceMin first.
  // debounceMax is the maximum time on which we accumulate metric updates
  // until they are sent to the backend.
  debounceMax: 2000,
  debounce: true, // Enable debouncing.
  // payloadFallbackSize is the number of metric updates will send together
  // once we reach the sizeLimit.
  // So, if we hit the size limit for beacon payloads, we'll group metrics into
  // batches of 50, assuming that that's a low enough number for them to fit.
  payloadFallbackSize: 50,
  sizeLimit: 64 * 1024, // max beacon payload size.
}

// Counter counts the time an event happens.
// https://docs.datadoghq.com/metrics/types/?tab=count#metric-types
export class Counter {
  constructor(
    // Name of the metric. E.g. webregister.sale_parked_counter.
    private readonly name: string,
    // Tags are Datadog tags. E.g. { isVIP: 'yes' }
    private readonly tags: Tags = {},
    // Sample is the percentage of the updates that will be sumitted to the
    // backend.
    // If you set it to 100, then all updates will be send to the backend.
    // If you set it to 1, then 1% of updates will be send to the backend.
    // This is not totally accurate, it's more of an approximation.
    private readonly sample: number = 100
  ) {
    this.tags = Object.assign({}, tags)
  }

  // inc increments the counter by value provided, depending on the result
  // of the sampling.
  public inc(value: number) {
    // Sample updates.
    if (this.sample < 100 && Math.random() > this.sample / 100) {
      return
    }

    // Store the counter update into the pending metrics.
    addCounter({
      type: metricTypeCounter,
      name: this.name,
      tags: this.tags,
      timestamp_ms: new Date().getTime(),
      increment: value,
    })

    // Send metric updates.
    if (settings.debounce) {
      debouncedSendMetrics()

      return
    }

    sendMetrics()
  }

  // Set a tag for the counter.
  // If a tag is already set, it'll be overriden.
  public tag(name: string, value: string) {
    this.tags[name] = value
  }
}

// Timer is a metric timer. It helps measuring the time a process lasts.
export class Timer {
  private hasBeenStarted: boolean = false

  constructor(
    // Name of the metric. E.g. webregister.sale_calc_timer.
    private readonly name: string,
    // Tags are Datadog tags. E.g. { isVIP: 'yes' }
    private readonly tags: Tags = {},
    // Sample is the percentage of the updates that will be sumitted to the
    // backend.
    // If you set it to 100, then all updates will be send to the backend.
    // If you set it to 1, then 1% of updates will be send to the backend.
    // This is not totally accurate, it's more of an approximation.
    private readonly sample: number = 100
  ) {
    this.tags = Object.assign({}, tags)
  }

  // start starts the current timer.
  // The timer can be started and stopped many times, but it has to be stopped
  // after being started again.
  public start() {
    if (this.hasBeenStarted) {
      trackError(
        new Error(
          `[${metricsLogPrefix}] trying to start a timer that has been started already`
        ),
        {
          timer: this.name,
          tags: this.tags,
        }
      )

      return
    }

    // If the browser doesn't support performance measures, we'll just skip it.
    if (!isPerformanceSupported()) {
      return
    }

    // Mark the start of the timer for later comparison.
    const measure = startPerformanceMeasure(this.name)
    if (!measure) {
      trackError(
        new Error(
          `[${metricsLogPrefix}] trying to start a timer but failed to mark the start`
        ),
        {
          timer: this.name,
          tags: this.tags,
        }
      )
    }

    this.hasBeenStarted = true
  }

  // stop stops a started timer. A timer that hasn't been started, cannot be
  // stopped.
  public stop() {
    if (!this.hasBeenStarted) {
      trackError(
        new Error(
          `[${metricsLogPrefix}] trying to stop a timer that has not been started`
        ),
        {
          timer: this.name,
          tags: this.tags,
        }
      )

      return
    }

    // If the browser doesn't support performance measures, we'll just skip it.
    if (!isPerformanceSupported()) {
      return
    }

    // Mark the end of the timer, and measure the difference.
    const measure = stopPerformanceMeasure(this.name, doNotUseUBETracking)
    if (!measure) {
      trackError(
        new Error(
          `[${metricsLogPrefix}] empty timer performance measure when stopping`
        ),
        {
          timer: this.name,
          tags: this.tags,
        }
      )

      return
    }

    // Reset the timer, so that it can be started again.
    this.hasBeenStarted = false

    // Sample metric updates.
    if (this.sample < 100 && Math.random() > this.sample / 100) {
      return
    }

    // Add the timer to the pending updates to send to the backend.
    addTimer({
      type: metricTypeTimer,
      name: this.name,
      tags: this.tags,
      timestamp_ms: new Date().getTime(),
      time_elapsed_ms: Math.round(measure.duration),
    })

    // Send metric updates.
    if (settings.debounce) {
      debouncedSendMetrics()

      return
    }

    sendMetrics()
  }

  // Set a tag for the timer.
  // If a tag is already set, it'll be overriden.
  public tag(name: string, value: string) {
    this.tags[name] = value
  }
}

// addTimer adds timer to the pending updates.
const addTimer = (timer: TimerMetric): void => {
  pendingUpdates.timers.push(timer)
}

// addCounter adds a counter to the pending updates.
// if the counter has been added already, it's updated and merged.
const addCounter = (counter: CounterMetric): void => {
  const counterKey = getKey(counter)
  const existingCounter = pendingUpdates.counters.get(counterKey)

  if (existingCounter && isEqual(existingCounter.tags, counter.tags)) {
    existingCounter.increment += counter.increment
    existingCounter.timestamp_ms = counter.timestamp_ms

    return
  }

  pendingUpdates.counters.set(counterKey, counter)
}

// isEqual returns true if both tags are the same.
const isEqual = (a: Tags, b: Tags): boolean => {
  return JSON.stringify(a) === JSON.stringify(b)
}

// chunk returns sub arrays of a given size.
function chunk(
  arr: Array<CounterMetric | TimerMetric>,
  size: number
): Array<Array<CounterMetric | TimerMetric>> {
  const result: Array<Array<CounterMetric | TimerMetric>> = []
  for (let i = 0; i < arr.length; i += size) {
    result.push(arr.slice(i, i + size))
  }

  return result
}

// getKey generates a key that is unique to the parts of the counter that we
// care about. Everything but the increment.
// This helps tell one counter from another.
const getKey = (counter: CounterMetric): string => {
  return JSON.stringify({ name: counter.name, tags: counter.tags })
}

// sendMetrics sends pending updates to the backend.
const sendMetrics = () => {
  const metrics = [
    ...pendingUpdates.timers,
    ...pendingUpdates.counters.values(),
  ]
  const payload = JSON.stringify(metrics)

  // Reset the pending updates.
  resetPendingUpdates()

  const path = '/metrics'

  const beaconPayload = new Blob([payload], { type: 'text/plan' })

  // Beacons are limited to a size of 64kb in some browsers.
  if (beaconPayload.size >= settings.sizeLimit) {
    // Grab 50 metric updates at a time, and try to send the beacons.
    const groupsOf50Metrics = chunk(metrics, settings.payloadFallbackSize)
    groupsOf50Metrics.forEach(group => {
      const newPayload = new Blob([JSON.stringify(group)], {
        type: 'text/plan',
      })

      if (newPayload.size >= settings.sizeLimit) {
        trackError(
          new Error(`[${metricsLogPrefix}] metrics beacon size limit exceeded`),
          {
            beaconSize: newPayload.size,
          }
        )

        return
      }

      navigator.sendBeacon(path, newPayload)
    })

    return
  }

  navigator.sendBeacon(path, beaconPayload)
}

// debouncedSendMetrics is sendMetrics wrapped with debouncing logic from
// lodash.
const debouncedSendMetrics = debounce(sendMetrics, settings.debounceMin, {
  maxWait: settings.debounceMax,
})
