import {
  Box,
  Link,
  LoaderSpinner,
  Modal,
  ModalBanner,
  ModalContent,
  ModalHeader,
} from '@lightspeed/design-system-react'
import { T, useT } from '@transifex/react'
import { isIOS } from '@vend/utilities'
import React, { ReactElement, useEffect, useState } from 'react'
import {
  PAGE_ROUTE,
  useTranslateValidationMessage,
  VALIDATION_MESSAGES,
  VendDomainSuffix,
} from '../../../../constants/constants'
import { useDisplayLSIDCallbackError } from '../../../../utils/lsidCallbackError'
import { SSOLogin } from '../SSOLogin/SSOLogin'
import { CredentialsForm } from './CredentialsForm'

interface CredentialsAuthenticationProps {
  isLoading: boolean
  storeName: string
  isSSOEnabled: boolean
  domainSuffix: VendDomainSuffix
  onUsernameChange: (username: string) => void
  onPasswordChange: (password: string) => void
  onSubmit: () => void
  isSubmitting: boolean
  isInvalidCredentials: boolean
  hasInternalError: boolean
  userDisabled: boolean
}

export const CredentialsAuthentication: React.FC<
  React.PropsWithChildren<CredentialsAuthenticationProps>
> = ({
  isLoading,
  storeName,
  isSSOEnabled,
  domainSuffix,
  onUsernameChange,
  onPasswordChange,
  onSubmit,
  isSubmitting,
  isInvalidCredentials,
  hasInternalError,
  userDisabled,
}) => {
  const t = useT()
  const translateValidation = useTranslateValidationMessage()
  useDisplayLSIDCallbackError()

  const SECURE_SIGNIN_URL = `https://secure${domainSuffix}${PAGE_ROUTE.signin}`

  const [showSSOLogin, setShowSSOLogin] = useState(isSSOEnabled)
  const [ssoUsername, setSSOUsername] = useState<string | undefined>()
  const [errorBannerMsg, setErrorBannerMsg] = useState<ReactElement | string>(
    ''
  )

  const genericErrMessage = translateValidation(VALIDATION_MESSAGES.generic)
  const credentialsInvalidErrMessage = translateValidation(
    VALIDATION_MESSAGES.credentialsInvalid
  )
  const userDisabledErrMessage = translateValidation(
    VALIDATION_MESSAGES.userDisabled
  )

  useEffect(() => {
    if (isSubmitting) {
      return
    }

    if (userDisabled) {
      setErrorBannerMsg(userDisabledErrMessage)
      return
    }

    if (hasInternalError) {
      setErrorBannerMsg(genericErrMessage)
      return
    }

    if (isInvalidCredentials) {
      setErrorBannerMsg(credentialsInvalidErrMessage)
    }
  }, [
    credentialsInvalidErrMessage,
    genericErrMessage,
    hasInternalError,
    isInvalidCredentials,
    isSubmitting,
    userDisabled,
    userDisabledErrMessage,
  ])

  useEffect(() => {
    if (!navigator.cookieEnabled) {
      setErrorBannerMsg(
        <>
          <T _str="Cookies are disabled in your browser. To log in to Lightspeed Retail, please enable cookies in your browser settings." />
        </>
      )

      return
    }

    const urlSearchQuery = window.location.search
    const params = new URLSearchParams(urlSearchQuery)

    const ssoError = params.get('sso_error')
    if (!ssoError) {
      return
    }

    if (ssoError === 'required_outlet_missing') {
      setErrorBannerMsg(
        t('User isn’t assigned to this outlet. Contact your IT administrator.')
      )
    } else {
      setErrorBannerMsg(
        t('Something went wrong. Contact your IT administrator.')
      )
    }

    // we want to remove this sso_error from the url so
    // that it is not propograted to any other pages
    params.delete('sso_error')
    window.history.replaceState(null, '', `?${params.toString()}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showCredentailsForm = (username: string) => {
    setSSOUsername(username)
    setShowSSOLogin(false)
  }

  const handleSubmit = () => {
    setErrorBannerMsg('')
    onSubmit()
  }

  const renderForm = () => {
    if (showSSOLogin) {
      return (
        <SSOLogin
          showCredentailsForm={showCredentailsForm}
          setErrorBannerMsg={setErrorBannerMsg}
        />
      )
    }

    return (
      <CredentialsForm
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit}
        onUsernameChange={onUsernameChange}
        onPasswordChange={onPasswordChange}
        ssoUsername={ssoUsername}
      />
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return (
        <ModalContent>
          <Box
            css={{
              width: '100%',
              height: '350px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <LoaderSpinner />
          </Box>
        </ModalContent>
      )
    }
    return (
      <>
        <ModalHeader>
          <div className="vd-flex vd-flex--align-center">
            <span className="vd-text-heading">
              <T _str="Log in to Lightspeed Retail (X-Series)" />
            </span>
          </div>
        </ModalHeader>

        <ModalContent>
          <div className="vd-flex vd-flex--align-center vd-flex--justify-between vd-mb8 vr-card--subtitle">
            <h2 className="vd-text-signpost" data-testid="vr-store-name">
              {storeName}
            </h2>
            {!isIOS() && (
              <Link
                data-track="not-your-store"
                href={SECURE_SIGNIN_URL}
                isSecondary
              >
                <T _str="Not your store?" />
              </Link>
            )}
          </div>
          {renderForm()}
        </ModalContent>
      </>
    )
  }

  return (
    <Modal focusTrapActive={false} pageModal size="small">
      {errorBannerMsg && (
        <ModalBanner variant="negative">{errorBannerMsg}</ModalBanner>
      )}

      {renderContent()}
    </Modal>
  )
}
