import {
  Button,
  Icon,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
} from '@lightspeed/design-system-react'
import { UT, useT } from '@transifex/react'
import React from 'react'
import { SigninBackground } from '../Signin/Background/SigninBackground'

const refreshPage = () => window.location.reload()

export const CookiesDisabled = () => {
  const t = useT()
  return (
    <section className="vr-container">
      <SigninBackground />
      <main className="vd-flex vd-flex--align-center vd-flex--justify-center vr-main">
        <div className="vd-flex vd-flex--justify-center">
          <Modal focusTrapActive={false} pageModal size="small">
            <ModalHeader>
              <h1 className="vd-text-heading">
                <Icon
                  icon="exclamation-triangle"
                  className="vd-mr1"
                  style={{ color: 'var(--hs-color-fg-no-default)' }}
                />
                {t('Change your browser settings to sign in', {
                  _context: 'Header',
                })}
              </h1>
            </ModalHeader>
            <ModalContent>
              <p className="vd-p">
                <UT
                  _inline
                  _str={`Cookies are required to run Lightspeed. Allow cookies in your browser settings and refresh the page. Learn more about cookies in our <a href="https://support.vendhq.com/hc/en-us/articles/7091774158095-Enabling-cookies-in-your-web-browser" target="_blank" rel="noopener noreferrer" className="vd-link vd-link--secondary" >Help Center</a>.`}
                />
              </p>
            </ModalContent>
            <ModalActions>
              <Button
                data-track="refresh-page"
                variant="supplementary"
                onClick={refreshPage}
              >
                {t('Refresh page', { _context: 'Button' })}
              </Button>
            </ModalActions>
          </Modal>
        </div>
      </main>
    </section>
  )
}
